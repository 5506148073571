const config = {
  // serverURL: 'http://localhost:5000',
  // serverURL: 'http://localhost:5001',
  // serverURL: 'https://chat.stefanzero.com',
  // serverURL: 'https://chat.stefan00.com',
  serverURL: 'https://express.stefanzero.net',
  // port: 23885,
  token: "keyboardCat"
}

export default config;
