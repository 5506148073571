import React from 'react';
import moment from 'moment';
/**
 *
 * @param {Date} date
 * @returns {str}
 */

function formatDay(date) {
  const dateUtc = moment.utc(date);
  const dateLocal = dateUtc.local();
  const dateStr = dateLocal.format('dddd, MMMM Do');
  // console.log(dateUtc, dateLocal, dateStr);
  return dateStr;
}

export default function DateSeparator(props) {
  return (
    <div className="date-separator">
      {formatDay(props.date)}
    </div>
  )
}