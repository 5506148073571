import React from 'react';

export default function UserPanel(props) {
  const users = props.users || [];
  // console.log('UserPanel:', JSON.stringify(users, null, 2));

  return (
    <ul className="user-list">
    {
      users.map((user, i) => {
        return (
          <li key={i} className="user-name">{user.name}</li>
        )
      })
    }
    </ul>
  )
}