import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// replace outdated package with Formik
// import Validate from 'react-validate-form';

function SendPanel({connectedUser, onSend}) {

  const [user, setUser] = useState(connectedUser);
  const handleUserChange = (event) => {
    setUser(event.target.value)
  }

  /*
   * Clear the message after Send is clicked
   */
  function submit(evt) {
    evt.preventDefault();
    const name = evt.target.name.value;
    const message = evt.target.message.value;
    evt.target.message.value = '';
    onSend({ name, message });
  }

  return (
    <Form onSubmit={ submit }>
        <Form.Group controlId="name">
          <Form.Label>Your name</Form.Label>
          <Form.Control as="input" placeholder="your name" name="name" value={user}  onChange={handleUserChange} required />
        </Form.Group>
        <Form.Group controlId="message">
          <Form.Label>Enter message</Form.Label>
          <Form.Control as="textarea" rows="3" placeholder="your message" name="message" required/>
        </Form.Group>
        <Button type="submit">Send</Button>
    </Form>
  )
}

export default SendPanel;