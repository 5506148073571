import React from 'react';

export default function MainPanelHeader(props) {
  return (
    <div className="main-panel-header">
      <span className="header-name">
        <span className="channel">channel: </span>
        {props.name}
      </span>
    </div>
  )
}