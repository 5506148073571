import React from 'react';
import { Card } from 'react-bootstrap';
import UserPanel from './UserPanel';

export default function SidePanel(props) {

  const users = props.values;
  return (
    <Card className="side-panel">
      <Card.Header className="side-panel-header">
        users
      </Card.Header>
      <Card.Body className="side-panel-body">
        <UserPanel users={users} />
      </Card.Body>
    </Card>
  )

}