/**
 * Central location for handling communication between server API and React client.
 *
 * Sets app state for:
 *
 *   rooms: rooms a user has joined, used for sideBar display
 *   currentRoom
 *   previousMessages
 *   messageUpdate
 *
 */

import cookie from 'cookie';
import io from 'socket.io-client';

export default class ChatController {

  constructor({ serverURL, updateUser, updateUsers, updateChat, addMessage }) {
    this.serverURL = serverURL;
    this.updateUser = updateUser;
    this.updateUsers = updateUsers;
    this.updateChat = updateChat;
    this.user = '';
    this.lastMessageDate = null;
    /*
     * initialize socket
     * set up handlers for "connect", "message" and "users"
     */
    this.socket = io.connect(serverURL);
    this.socket.on('connect', () => {
      console.log('connected', this.socket.id, this.user);
      if (this.user) {
        this.socket.emit('user', {name: this.user, id: this.socket.id});
      }
      this.load(this.lastMessageDate);
    });
    this.socket.on('message', data => {
      this.lastMessageDate = new Date();
      console.log('message received', JSON.stringify(data));
      const { name, message, created } = data;
      // TODO: convert created from UTC to local
      // const createdUtc = moment(created);
      // // const createdLocal = createdUtc.l

      if (name && message && created) {
        addMessage({ name, message, created });
      }
    });
    this.socket.on('users', data => {
      // console.log('users received', JSON.stringify(data));
      this.updateUsers(data);
    })
    this.sendMessage = this.sendMessage.bind(this);
  }

  async load(lastMessageDate) {
    let url = `${this.serverURL}/api/messages/after`
    // TODO: add function appendChat
    // if (lastMessageDate) {
    //   url += `?date=${moment().toISOString(lastMessageDate)}`
    // }
    const response = await fetch(url);
    const cookies = cookie.parse(document.cookie);
    console.log(JSON.stringify(cookies));
    if (cookies.user) {
      this.user = cookies.user;
      if (this.socket) {
        this.socket.emit('user', {name: this.user, id: this.socket.id});
      }
      this.updateUser(this.user);
    }
    const messages = await response.json();
    this.updateChat(messages);
  }

  sendMessage({ name, message }) {
    const time = new Date();
    this.socket.emit('send', { name, message, time });
    if (this.user !== name) {
      document.cookie = `user = ${name}`;
      this.user = name;
      this.updateUser(name);
      const id = this.socket.id;
      this.socket.emit('user', {id, name, time} )
    }
  }

}