import parse from 'html-react-parser';
import moment from 'moment';
import React from 'react';

/**
 * Remove seconds
 *
 * @param {Date} time
 * @returns {string
 */
function formatTime(UTCTime) {
  const timeStr = moment.utc(UTCTime).local().format('h:mm A');
  return timeStr;
}

export default function MessagePanel(props) {
  // console.log(`MessagePanel: ${props.name}, ${props.created}, ${props.message}`);
  return (
    <div className="message-panel">
      <div className="message-header">
        <span className="user">{props.name}</span>
        <span className="time">{formatTime(props.created)}</span>
      </div>
      <div className="message-content">
        {parse(props.message)}
      </div>
    </div>
  )
}