import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import SidePanel from './components/SidePanel';
import MainPanel from './components/MainPanel';
import ChatController from './ChatController';

/*
“Who are you ?” said the Caterpillar.
This was not an encouraging opening for a
conversation. Alice replied, rather shyly, “ I—I
hardly know, sir, just at present—at least I
know who I was when I got up this morning,
but I think I must have been changed several
times since then.”
“What do you mean by that ?” said the
Caterpillar sternly. “Explain yourself !”
“ I can’t explain myself, I’m afraid, sir,”
said Alice, “because I’m not myself, you see.”
 */
const now = new Date();
const twoHours = 2 * 60 * 60 * 1000;
const start = new Date(now.getTime() - twoHours);
let currentTimestamp = start.getTime();
const minDeltaMinutes = 1;
const maxDeltaMinutes = 10;
const getNextDate = () => {
  const minDelta = minDeltaMinutes * 60 * 1000;
  const maxDelta = maxDeltaMinutes * 60 * 1000;
  const delta = minDelta + Math.random() * (maxDelta - minDelta)
  currentTimestamp += delta;
  const newDate = new Date(currentTimestamp)
  return newDate.toUTCString()
};
const mockChat = {
  header: {
    name: '#general'
  },
  messages: [
    {
      name: 'The Caterpillar',
      created: getNextDate(),
      message: 'Who are you?'
    },
    {
      name: 'Alice',
      created: getNextDate(),
      message: 'I hardly know, sir, just at present. ' +
        'At least I know who I was when I got up this morning, ' +
        'but I think I must have been changed several times since then.'
    },
    {
      name: 'The Caterpillar',
      created: getNextDate(),
      message: 'What do you mean by that?  Explain yourself!'
    },
    {
      name: 'Alice',
      created: getNextDate(),
      message: 'I can’t explain myself, I’m afraid, sir, because I’m not myself, you see.'
    }
  ],
  users: [
    'The Caterpillar',
    'Alice'
  ],
  user: 'Alice'
}

const channelName = 'general';

class App extends Component {

  constructor (props) {
    super(props);
    const serverURL = props.config.serverURL || window.location.origin;
    this.state = {
      user: '',
      users: [],
      chat: mockChat
    };
    // this.actions = mockActions;
    this.updateUser = this.updateUser.bind(this);
    this.updateUsers = this.updateUsers.bind(this);
    this.updateChat = this.updateChat.bind(this);
    this.addMessage = this.addMessage.bind(this);
    this.chatController = new ChatController(
      {
        serverURL,
        updateUser: this.updateUser,
        updateUsers: this.updateUsers,
        updateChat: this.updateChat,
        addMessage: this.addMessage
      }
    );
    this.actions = { onSend: this.chatController.sendMessage };
  }

  updateUser(user) {
    this.setState({user});
  }

  updateUsers(users) {
    this.setState({users});
  }

  updateChat(messages) {
    this.setState({chat: {header: {name: channelName}, messages}});
  }

  addMessage(message) {
    // console.log('addMessage', JSON.stringify(message, null, 2));
    this.setState(prevState => ({
      chat : {...prevState.chat, messages: [...prevState.chat.messages, message]}
    }));
  }

  render() {
    const values = {...this.state.chat, user: this.state.user}
    return (
      <div className="App">
        <table className="app-table">
          <tbody>
            <tr className="row-1">
              <td className="user-column">
                <SidePanel values={this.state.users} />
              </td>
              <td className="message-column">
                <MainPanel values={values} actions={this.actions} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default App;
