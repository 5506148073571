import React, { useRef, useLayoutEffect } from 'react';
import MainPanelHeader from  './MainPanelHeader';
import MessagePanel from './MessagePanel';
import SendPanel from './SendPanel';
import DateSeparator from './DateSeparator';
import { Card } from 'react-bootstrap';
import moment from 'moment';

function hasDayChanged(timeStr1, timeStr2) {
  const d1utc = moment.utc(timeStr1);
  const d2utc = moment.utc(timeStr2);
  const d1local = d1utc.local();
  const d2local = d2utc.local();
  const d1date = d1local.format('YYYY-MM-DD');
  const d2date = d2local.format('YYYY-MM-DD');
  const dayChanged = d1date !== d2date;
  // console.log(`${timeStr1}: ${d1local}, ${timeStr2}: ${d2local}`);
  return dayChanged;
}

export default function MainPanel(props) {
  const refBody = useRef();

  useLayoutEffect(() => {
    const { current } = refBody;
    // window.refBody = current;
    current.scrollIntoView(false, { block: 'end' });
    // scroll to bottom
    current.scrollTop += current.scrollHeight;
  })

  const values = props.values;
  const actions = props.actions;
  const { messages, user } = values;
  return (
    <Card className="main-panel">
      <Card.Header>
        <MainPanelHeader {...values.header} />
      </Card.Header>
      <Card.Body className="main-panel-body" ref={refBody}>
      {
        messages && messages.map((message, i) => (
          <div key={i}>
            {
              ((i === 0) || (hasDayChanged(message.created, messages[i - 1].created))) &&
              <DateSeparator date={message.created} />
            }
            <MessagePanel {...message} />
          </div>
        ))
      }
      </Card.Body>
      <Card.Footer>
        <SendPanel onSend={actions.onSend} user={user} />
      </Card.Footer>
    </Card>
  )
}